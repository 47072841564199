<template>
  <main>
    <div v-if="userType == 'retailer'">
      <retailerNavigation v-if="!isShopify" />
      <v-main app>
        <div
          :class="{
            'retailer-main-container-import-list': !isShopify,
            'product-detail': isShopify,
          }"
          class="page-wrapper product-detail"
        >
          <productDetail />
        </div>
      </v-main>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="no-access">You don't have access to this panel</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import productDetail from "@/components/retailer/productDetail.vue";
import retailerNavigation from "@/components/navigation/retailer.vue";
import {  isShopifyEmbedded} from '@shopify/app-bridge/utilities';
export default {
  data() {
    return {
      isShopify: false,
      userType: "retailer",
    };
  },
  components: { productDetail, retailerNavigation },

  created() {
    if (isShopifyEmbedded()) {
      this.isShopify = true;
    }
  },
};
</script>

<style lang="scss">
.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}
.product-detail {
  .product-detail-header {
    margin-top: 30px !important;
    padding-top: 0;
    padding-bottom: 10px;
  }
}

.retailer-main-container-import-list {
  @media screen and (min-width: 1621px) {
    width: calc(100% - 270px) !important;
    margin-left: auto;
  }
  @media screen and (min-width: 300px) {
    width: calc(100% - 80px);
    margin-left: auto;
    margin-right: 5px;
  }
}
</style>
